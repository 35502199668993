
function typeToNum(type)
{
    switch (type){
        case "Коляски":
            return 0
        case "Duo Pod Муфты":
            return 1
        case "Аксессуары":
            return 2
        case "Адаптеры":
            return 3
        case "Расширения- Рамы":
            return 4
        default:
            return 5

    }
}

export default function compare(a,b)
{
    return typeToNum(a.type) - typeToNum(b.type)
}