import React, {useState} from "react";

export default function CustomToggle({text, onClick, selected})
{
    return(
        <button className= {!selected?"category-button":"category-button-selected"} onClick={onClick}>
            <h3 className="category-text">
                {text}
            </h3>
        </button>)
}
