import {useRef, useState} from "react";
import {Link, Navigate, useNavigate, useSearchParams} from "react-router-dom";
import {Breadcrumb, BreadcrumbItem, Card, CardTitle, Container, Table} from "reactstrap";
import {Assortment} from "./Assortment";

function Category({name, params, img})
{
    const navigate = useNavigate()
    return(
            <Card className='category-tile p-2 justify-content-end' onClick={()=> navigate('/catalog?'+params)}>
                <img className="flex-grow-1" src={img} alt={name}/>
                <CardTitle tag="h7" className="text-center">{name}</CardTitle>
            </Card>)
}

function CollectionTilePanel({name, children})
{
    return(<div>
            <h2>{name}</h2>
            <div className="category-tiles-container">
                {children}
            </div>
        </div>)
}

export function Collections()
{
    return(<CollectionTilePanel name="Коллекции">
            <Category name='Peach' params="collection=Peach" img='peach.jpg'/>
            <Category name='Land rover' params="collection=iCandy for Land Rover" img="land-rover.jpg"/>
            <Category name='Lime' params="collection=Lime" img="lime.jpg"/>
            <Category name='Orange' params="collection=Orange" img="orange.jpg"/>
            <Category name='Peach D. C. Cerium' params="collection=Peach Designer Collection Cerium" img="peach designed.jpg"/>
            <Category name='Universal' params="collection=Universal" img="universal.jpg"/>
    </CollectionTilePanel>)
}

export function Types()
{
    return(<CollectionTilePanel name="Типы">
        <Category name='Коляски' params="type=Коляски" img='kalaska.png'/>
        <Category name='Duo Pod Муфты' params="type=Duo Pod Муфты" img="mufta.jpg"/>
        <Category name='Адаптеры' params="type=Адаптеры" img="adapter.jpg"/>
        <Category name='Аксессуары' params="type=Аксессуары" img="access.jpg"/>
        <Category name='Расширения - Рамы' params="type=Расширения- Рамы" img="ramp.jpg"/>
    </CollectionTilePanel>)
}

export default function Catalog()
{
    const [params] = useSearchParams()
    let collection = params.get("collection")
    let type = params.get("type")
    let top = null
    if(type == null && collection == null)
    {
        top = (<><Collections/><br/><Types/></>)
    }
    return (<Container>
        <Breadcrumb>
            <BreadcrumbItem>
                <Link to='/'>
                    Главная
                </Link>
            </BreadcrumbItem>
             <BreadcrumbItem>
                 <Link to='/catalog'>
                     Каталог
                 </Link>
             </BreadcrumbItem>
            {(type != null)&&(<BreadcrumbItem active>
                 <Link to={"/catalog?type="+type}>
                     {type}
                 </Link>
             </BreadcrumbItem>)}
            {(collection != null)&&(<BreadcrumbItem active>
                 <Link to={"/catalog?collection="+collection}>
                     {collection}
                 </Link>
             </BreadcrumbItem>)}

        </Breadcrumb>
        {top}
        <h2 className="mt-5">Каталог</h2>
        <div className="d-flex">
            <div>
            </div>
            <div>
                <Assortment collection={collection} type={type}/>
            </div>
        </div>
    </Container>)
}