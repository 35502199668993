
const target = "http://localhost";
export default function getImage(article, index)
{
    return `prod_images/${article}/${index}.jpg`;
}

export async function getImagesCount(article)
{
    return await (await fetch("product/image/" + article)).json();
}