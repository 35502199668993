import { Assortment } from "./components/Assortment";
import { FetchData } from "./components/FetchData";
import { Home } from "./components/Home";
import {Route} from "react-router-dom";
import Page404 from "./Page404";
import Catalog from "./components/Catalog";
import ProductPage, {ProductPageParent} from "./components/ProductPage";
import UserInfo from "./components/UserInfo";

const AppRoutes = [
  {
    index: true,
    element: <Home />
  },
  {
    path:"/catalog/:id",
    element: <ProductPageParent/>
  },
  {
    path: '/catalog',
    element: <Catalog/>
  },
  {
    path: '/fetch-data',
    element: <FetchData />
  },
  {
    path: '/info',
    element: <UserInfo/>
  },
  {
    path:'/*',
    element: <Page404/>
  },
];

export default AppRoutes;
