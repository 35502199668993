import React, {useEffect, useState} from 'react';
import Product from "./Product";
import axios from "axios";
import compare from "../productSort";

export function Assortment({type, collection, maxPrice, minPrice, offset, count})
{
  const [products, setProducts] = useState([])
    useEffect(() => {
       axios.get("product/", {params: {
               type:type,
               collection:collection,
               minPrice:minPrice,
               maxPrice:maxPrice,
               offset:offset,
               count:count,
           }}).then((result) => {
           if (result.status < 300) {
               setProducts(result.data)
           }
      })
   }, [type, collection, maxPrice, minPrice]);

        if(products.length >0)
        return(<div className="d-flex flex-wrap">
          {products.sort((a,b) => {return compare(a,b)}).map(it =>
            (<Product key = {it.id} product={it}/>)
          )}
          </div>)
        return (<p>Похоже, что тут ничего нет</p>)
}
