import React from "react";
import {Link} from "react-router-dom";


export function TileContainer({children})
{
    return(
        <>
            <div className="tile-container">
                {children}
            </div>
    </>)
}

export function Tile({text, image, page})
{
    return (
        <Link className="tile-item" to={page}> 
            <img src={image} alt={text}/>
            <p>{text}</p>
    </Link>)
}