import React, {useState} from "react"
import {Badge, Card, CardSubtitle, CardTitle} from "reactstrap";
import getImage from "../GetImage";
import {useNavigate} from "react-router-dom";

export default function Product({product})
{
    const [mouseOn, changeMouse] = useState(false);
    const navigate = useNavigate()
    function on_click(e)
    {
        if (product.count <= 0)
            return
        alert(product.name)
        e.target.content="В корзине"
    }
    return(
        <Card className="product p-2 "
              onClick={e => navigate("/catalog/"+ product.id)}
              outline
              color= {mouseOn?"dark":""}
              onMouseEnter={()=> changeMouse(true)}
              onMouseLeave={() => changeMouse(false)}>
                <img src={getImage(product.article, ((!mouseOn) || product.countImages<=1)?0:1)} alt={product.name}/>

            <div className="position-absolute top-0 mt-2 d-flex">
            {(product.calculatedPrice !== product.price) && (<Badge color="primary" className="me-2" >-{100 - Math.round(product.calculatedPrice/product.price*100)}%</Badge>)}
            {(product.hot) && (<Badge color="danger" className="me-2" >hot</Badge>)}
            {(product.new) && (<Badge color="warning" className="me-2" >new</Badge>)}
            </div>

            <CardTitle tag="h5">
                {product.name}
            </CardTitle>
            <CardSubtitle className="mb-2 text-black">
                {(product.calculatedPrice !== product.price) && (<strike>{product.price}</strike>)}
                <span style={{fontSize:"1.3em"}}> {(product.calculatedPrice !== product.price)?product.calculatedPrice:product.price} руб./шт</span>
            </CardSubtitle>
            {/*<Button className="w-75 " active={!(product.count > 0)} color={product.count > 0?"primary":"dark"} onClick={on_click}>{product.count > 0?"В корзину":"Нет в наличии"}</Button>*/}
        </Card>)
}
