import React, {Component} from 'react';
import {
  Button, Collapse,
  Form, FormFeedback, Input, Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader, Table
} from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope, faPhone} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import {setFunc} from "./useMenu";

export class NavMenu extends Component {
  static displayName = NavMenu.name;

  constructor (props) {
    super(props);
    this.state = {
      collapsed: true,
      phone:"",
      name:"",
      message:"",
      collapsedBar: false,
      btnText: "Заказать звонок"
    };
    this.toggleNavbar = this.toggleNavbar.bind(this);

    setFunc(this.toggleNavbar)
  }

  toggleNavbar(e, name = null) {
    this.setState({
      collapsed: !this.state.collapsed,
      lastPageName:name,
      btnText: "Заказать звонок",
      phone:"",
      name:"",
      message:"",
    });
  }

  toggleNavMenu()
  {
    this.setState({collapsedBar:!this.state.collapsedBar})
  }

  async orderCall(e)
  {
    e.preventDefault();
    if(this.state.name === "" && this.state.phone ==="")
    {
        return;
    }
    let message = this.state.message ===""?"empty":this.state.message;
    await axios.post("/user/call", null,{params:{
          name:this.state.name,
          phone:this.state.phone,
          message:message,
          art:this.state.lastPageName
      }});
    this.setState({btnText:"Отправлено"})
  }

  phoneChange(e) {
    if((e.target.value.length === 0 ||
        (e.target.value[e.target.value.length-1] >='0' && e.target.value[e.target.value.length-1] <= "9")
        ||(e.target.value.length === 1 && e.target.value[0] === '+')) && e.target.value.length <= 12)
      this.setState({phone:e.target.value});
  }

  nameChange(e) {
    this.setState({name:e.target.value});
  }

  messageChange(e) {
    this.setState({message:e.target.value});
  }

  render() {
    return (
      <header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" container light>
          <NavbarBrand tag={Link} to="/">
            <img className="brand-img" src="icon.webp" alt="ICandy"/>
          </NavbarBrand>
          <NavbarToggler onClick={this.toggleNavMenu.bind(this)} className='me-2'/>
            <Collapse isOpen={!this.state.collapsedBar} navbar>
              <Nav navbar>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/">Главная</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/catalog">Каталог</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} className="text-dark" to="/fetch-data">Мир ICandy</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className="text-dark" onClick={this.toggleNavbar}>+7 (915) 055-3034</NavLink>
                </NavItem>
              </Nav>
            </Collapse>

        </Navbar>
        <Offcanvas isOpen={!this.state.collapsed}  direction="end" toggle = {this.toggleNavbar}>
          <OffcanvasHeader toggle={this.toggleNavbar}>
            Контакты
          </OffcanvasHeader>
          <OffcanvasBody>
              <Table>
                <tbody>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faPhone} size="lg" />
                  </td>
                  <td>
                    <Link to={'tel:+79150553034'}  className="text-dark"><h5> +7 (915) 055-3034</h5></Link>
                  </td>
                </tr>
                <tr>
                  <td>
                    <FontAwesomeIcon icon={faEnvelope} size="lg" />
                  </td>
                  <td>
                    <Link to="mailto:Icandy.eurasia@gmail.com" className="text-dark">
                      <h5>Icandy.eurasia@gmail.com</h5>
                    </Link>
                  </td>
                </tr>
                </tbody>
              </Table>
              <h4>Заказать звонок</h4>
              <Form >
                <Input name="name" placeholder="Имя" onChange={this.nameChange.bind(this)} className="mb-3"></Input>
                <Input name="phone" onChange={this.phoneChange.bind(this)} placeholder="+7 ___ ___-__-__" className="mb-3" value={this.state.phone}/>
                <Input tag="textarea" name="message" placeholder="Сообщение" onChange={this.messageChange.bind(this)} className="mb-3"></Input>
                <Button className="w-100" onClick={this.orderCall.bind(this)}>{this.state.btnText}</Button>
              </Form>
          </OffcanvasBody>
        </Offcanvas >
      </header>
    );
  }
}
