import React, { Component } from 'react';
import { NavMenu } from './NavMenu';
import {Link} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTelegram, faVk, faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import useMenu from "./useMenu";


export class Layout extends Component {
  static displayName = Layout.name;



  render() {
    return (
      <div >
        <NavMenu />
        <div >
          {this.props.children}
        </div>
          <footer className="bg-dark p-3 mt-5" >
              <div className="d-flex flex-column align-items-center">
              <div className="d-flex justify-content-around w-75">
                  <Link className="text-light h5" to="catalog">Каталог</Link>
                  <Link className="text-light h5" onClick={()=> useMenu(null)} >Контакты</Link>
              </div>
                  <div className="d-flex justify-content-around w-75 mt-5">
                      <p className="text-light">ICandy Russia 2023</p>
                      <span>
                          <Link to="https://t.me/+79150553034" className="me-2">
                              <FontAwesomeIcon icon={faTelegram} size="2xl" />
                          </Link>
                          <Link to="https://vk.com/icandyrussia" className="me-2">
                              <FontAwesomeIcon icon={faVk} size="2xl" />
                          </Link>
                          <Link to="https://wa.me/79150553034">
                              <FontAwesomeIcon icon={faWhatsapp} size="2xl" />
                          </Link>
                      </span>
                      <Link className="text-light" to="info">
                          Пользовательское соглашение
                      </Link>
                  </div>
              </div>
              
          </footer>
      </div>
    );
  }
}
