import React, { Component } from 'react';
import {Container} from "reactstrap";

export class FetchData extends Component {
  static displayName = "Мир ICandy";

  constructor(props) {
    super(props);
  }

  render() {
    return (
        <Container>
          <h1>Наши коляски iCandy (Великобритания) - в России и в мире</h1>
          <p>Детская коляска эры высоких технологий должна соответствовать многим требованиям,
            в их числе:
            <ui>
              <li>простота использования</li>
              <li>бескомпромиссная надежность</li>
              <li>красота, радующая глаз</li>
            </ui>
            Коляски мировой торговой марки iCandy остаются неизменными фаворитами у
            родителей благодаря их отработанному до совершенства дизайну «на все времена»,
            превосходному качеству сборки и практичности, облегчающей заботы повседневности.
            Коляски iCandy - это выбор британских знаменитостей. Им отдали предпочтение
            модный дизайнер и певица Виктория Бэкхем, герцогиня Сассекская Меган Маркл, внучка
            королевы Елизаветы II Зара Тиндалл- призёр Олимпиады Лондон-2012 по конному
            спорту (на фото- с коляской iCandy for Land Rover).</p>
          <img style={{
            display:"block",
            margin:"auto",
            maxWidth:"80vw"
          }} src="010 zara-and-lena-icandy-z.jpg" alt="Icandy"/>
          
          <p>
            Коляски iCandy предназначены для всех ландшафтов современного мира - от тротуаров
            оживленных городских улиц до травяных поверхностей парков и лугов, от крутых
            поверхностей холмов до тенистых лесных тропинок- окружая вашего ребенка
            комфортом от невероятно мягкой и удобной поездки. Благодаря их легкому и
            компактному шасси, коляски  безальтернативно удобны к перевозке в Вашем
            автомобиле, использованию в общественном транспорте. Наша особая гордостьинновационная система складывания шасси коляски, которая даёт возможность
            удобного размещения в багажнике автомобиля, а также свободу пешего перемещения
            со сложённой коляской через любые узкие двери и проемы.
            Длину ручки коляски можно мягко отрегулировать в соответствии с любым
            предпочтением родителя.
            Наша особая гордость- инновационная система складывания коляски, которая даёт
            возможность удобного размещения в задней части вашего автомобиля, а также свободу
            пешего перемещения со сложённой коляской через любые узкие двери и проемы.
          </p>
        </Container>
    );
  }

}
