import React, {Component, Fragment, useEffect, useState} from "react";
import axios from "axios";
import {
    Badge,
    Button,
    Card,
    CardTitle,
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem, Table
} from "reactstrap";
import getImage from "../GetImage";
import {Assortment} from "./Assortment";
import useMenu from "./useMenu";
import {useParams} from "react-router-dom";

export default class ProductPage extends Component
{
    constructor(props) {
        super(props);

        this.state = {index:0, animating:false, product:this.props.product}
        this.nameConverter = {
            box:"Что в коробке",
            color:"Цветовая палитра",
            chssis:"Тип шасси и рамы",
            weight:"Вес с упаковкой, кг",
            volume:"Объем в упаковке, м3",
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(prevProps.product !== this.props.product )
        {
            this.setState({product:this.props.product, index:0})
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    getTable()
    {
        if(this.state.product == null)
            return (<p>Loading</p>)
        let index = 0;
        let params = []
        for (let i in this.state.product){
            if( index >=6 && index <=10 && this.state.product[i] != null)
            {
                params.push(<Fragment key = {index}>
                    <tr>
                    <th scope="row">
                        {this.nameConverter[i]}
                    </th>
                    <td>
                        {this.state.product[i]}
                    </td>
                    </tr>
                </Fragment>)
            }
            index+=1;
        }
        return(
            <Table className="stat-table">
                <tbody>
                {params}
                </tbody>
            </Table>);
    }

    getBuyButton()
    {
        let price = this.state.product.price === this.state.product.calculatedPrice?(<p>{this.state.product.calculatedPrice}руб./шт</p>):(
            <><p className="text-muted"><s>{this.state.product.price} руб./шт</s></p>
            <p>{this.state.product.calculatedPrice} руб./шт</p></>
        )
        let badge = null;
        if(this.state.product.price !== this.state.product.calculatedPrice)
            badge = (<Badge className="w-25 mb-2" color="danger">
                {100 - Math.round(this.state.product.calculatedPrice/this.state.product.price*100)}%
            </Badge>)
        return (<Card className="price-card">
            {badge}
            <CardTitle style={{fontSize:"1.5em"}} >
                {price}
            </CardTitle>
            <Button color="success" onClick={() =>{
                useMenu(this.state.product.fullName)
            }}>Заказать звонок</Button>
            </Card>)
    }

    getCarousel()
    {
        const activeIndex = this.state.index;
        const animating = this.state.animating;

        let items = []
        for (let i = 0; i < this.state.product.countImages; i++)
            items.push(getImage(this.state.product.article, i))
        const next = () => {
            if (animating) return;
            const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
            this.setState({index: nextIndex});
        };

        const previous = () => {
            if (animating) return;
            const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
            this.setState({index: nextIndex});
        };

        const goToIndex = (newIndex) => {
            if (animating) return;
            this.setState({index: newIndex});
        };

        const slides = items.map((item, index) => {
            return (
                <CarouselItem
                    key = {index}
                    className="carousel-item"
                    tag="div"
                    onExiting={() => this.setState({animating: true})}
                    onExited={() => this.setState({animating: false})}
                >
                    <img className="carousel-image" src={item} alt={this.state.product.name}/>
                </CarouselItem>
            );
        });

        return (
            <div className="carousel-div">
                <Carousel activeIndex={activeIndex} next={next} previous={previous} dark fade>
                    <CarouselIndicators
                        activeIndex={activeIndex}
                        onClickHandler={goToIndex}
                     items={[]}/>
                    {slides}
                    <CarouselControl
                        direction="prev"
                        directionText="Предыдущая"
                        onClickHandler={previous}
                    />
                    <CarouselControl
                        direction="next"
                        directionText="Следующая"
                        onClickHandler={next}
                    />
                </Carousel>
            </div>
        );
    }
    render() {
        if(this.state.product != null)
            return(
                <div >
                    <h1 className='m-3 ms-5'>{this.state.product.name}</h1>
                    <div className="product-container">
                        {this.getCarousel()}
                        <div className="m-3 flex-grow-1">
                            <p>Код товара - {this.state.product.article}</p>
                            <p>Полное наименоване - {this.state.product.fullName}</p>
                            <p>Коллекция - {this.state.product.collection}</p>
                            <h2>Характеристики</h2>
                            {this.getTable()}
                        </div>
                        {this.getBuyButton()}
                    </div>

                    <div className="m-5">
                        <h2 >Похожие товары</h2>
                        <Assortment collection={this.state.product.collection}></Assortment>
                    </div>

                </div>

            )
    }
}

export function ProductPageParent()
{
    const [product, setProd] = useState(null)
    const {id} = useParams();
    useEffect(()=>{
        axios.get(`/product/${id}`).then(res =>{
            if(res.status < 300)
            {
                setProd(res.data)
            }
        })
    },[id])
    if(product != null)
        return (<ProductPage product = {product}/>)
    return (<p>Загрузка</p>)
}