import React, { Component } from 'react';
import {Tile, TileContainer} from "./TileView";
import {ButtonToolbar, Container} from "reactstrap";
import Product from "./Product";
import CustomToggle from "./CustomToggle";

export class Home extends Component {
  static displayName = Home.name;
  
  constructor(props)
  {
      super(props)
      
      this.state = {chapt:0, products:[],filteredProducts:[] }
  }

  async componentDidMount() {
      let products = await fetch("product")
      console.log(products)
      let json = await products.json()
      console.log(products)
      this.setState({...this.state, products:json, filteredProducts: this.recomendedFilter(json)})
  }

  getNew()
  {
      this.setState({filteredProducts: this.state.products.filter(it => it.new)})
  }
  getHot()
  {
      this.setState({filteredProducts: this.state.products.filter(it => it.hot)})
  }

  recomendedFilter(prods)
  {
      return prods.filter(it => it.type == "Коляски").sort((a,b) => {return (a.calculatedPrice - a.price) -  (b.calculatedPrice - b.price)})
  }

  getRec()
  {
      this.setState({filteredProducts:this.recomendedFilter(this.state.products)})
  }
  updateChapter(chaptNum)
  {
      this.setState({chapt:chaptNum})
      console.log(this.state)
      switch (chaptNum)
      {
          case 0:
              this.getRec();
              break;
          case 1:
              this.getNew();
              break;
          case 2:
              this.getHot();
              break;
      }

  }
  
  render() {
      
    return (
        <>
        <TileContainer>
            <Tile text="Peach" image="peach7-plush_fabric_desk.webp" page="/catalog?collection=Peach"/>
            <Tile text="Cerium" image="safe-sleeping-3560.webp" page="catalog?collection=Peach Designer Collection Cerium"/>
            <Tile text="Lime" image="lime-prew.webp" page="/catalog?collection=Lime"/>
            <Tile text="ICandy for Land Rover" image="icandt for land.jpg" page="/catalog?collection=ICandy for Land Rover"/>
            <Tile text="Orange" image="orange-comfort-d.webp" page="/catalog?collection=Orange"/>
        </TileContainer>
      <Container>
          <ButtonToolbar className="m-3">
              <CustomToggle text = "Рекомендуем" selected={this.state.chapt === 0} onClick={()=> this.updateChapter(0)} />
              <CustomToggle text = "Новинки" selected={this.state.chapt === 1} onClick={()=> this.updateChapter(1)} />
              <CustomToggle text = "Хиты продаж" selected={this.state.chapt === 2} onClick={()=> this.updateChapter(2)} />
          </ButtonToolbar>
          <div className="d-flex flex-wrap">
              {this.state.filteredProducts.map((val) => {
                  return (<Product key = {val.id} product={val}/>)
              })}
          </div>
      </Container>
        </>
    );
  }
}
