import {Container} from "reactstrap";

export default function UserInfo()
{
    return (<Container> <h1>Пользовательское соглашение</h1>
        <p>Пользовательское Соглашение
        Настоящее Пользовательское Соглашение (Далее Соглашение) регулирует отношения между (далее ICandy или Администрация) с одной стороны и пользователем сайта с другой.
        Сайт ICandy не является средством массовой информации.
        <br/>
        Используя сайт, Вы соглашаетесь с условиями данного соглашения.
        Если Вы не согласны с условиями данного соглашения, не используйте сайт ICandy!
        <br/>
        Предмет соглашения
        Администрация предоставляет пользователю право на размещение на сайте следующей информации:
        <ul>
            <li>
                Текстовой информации
            </li>
            <li>
                Ссылок на материалы, размещенные на других сайтах
            </li>
        </ul>
        Права и обязанности сторон
        Пользователь имеет право:
        <ul>
            <li>осуществлять поиск информации на сайте</li>
            <li>получать информацию на сайте</li>
            <li>использовать информацию сайта в личных некоммерческих целях</li>
        </ul>
        Администрация имеет право:
        <ul>
            <li>по своему усмотрению и необходимости создавать, изменять, отменять правила</li>
            <li>ограничивать доступ к любой информации на сайте</li>
            <li>создавать, изменять, удалять информацию</li>
        </ul>
        Пользователь обязуется:
        <ul>
            <li>не нарушать работоспособность сайта</li>
            <li>не размещать материалы рекламного, эротического, порнографического или оскорбительного характера, а также иную информацию, размещение которой запрещено или противоречит нормам действующего законодательства РФ</li>
            <li>не использовать скрипты (программы) для автоматизированного сбора информации и/или взаимодействия с Сайтом и его Сервисами</li>
        </ul>
        Администрация обязуется:
        <ul>
            <li>поддерживать работоспособность сайта за исключением случаев, когда это невозможно по независящим от Администрации причинам.</li>
            <li>защищать информацию, распространение которой ограничено или запрещено законами путем вынесения предупреждения либо удалением учетной записи пользователя, нарушившего правила</li>
        </ul>
        Ответственность сторон
        <ul>
            <li>пользователь лично несет полную ответственность за распространяемую им информацию</li>
            <li>администрация не несет никакой ответственности за достоверность информации, скопированной из других источников</li>
            <li>администрация не несет никакой ответственности за услуги, предоставляемые третьими лицами</li>
            <li>в случае возникновения форс-мажорной ситуации (боевые действия, чрезвычайное положение, стихийное бедствие и т. д.) Администрация не гарантирует сохранность информации, размещённой Пользователем, а также бесперебойную работу информационного ресурса</li>
        </ul>
        Условия действия Соглашения
        Данное Соглашение вступает в силу при любом использовании данного сайта.
        Соглашение перестает действовать при появлении его новой версии.
        Администрация оставляет за собой право в одностороннем порядке изменять данное соглашение по своему усмотрению.
        Администрация не оповещает пользователей об изменении в Соглашении.</p></Container>)
}